import React, { useContext, lazy } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { RouteComponentProps, Switch } from "react-router";
import { Header, Navigation, Loading } from "../../components";
import { makeStyles } from "@material-ui/styles";
import AuthContainer from "../../features/auth/state";

const LoginPage = lazy(() => import("../../features/auth/pages/login"));
const ChangePasswordPage = lazy(() =>
  import("../../features/auth/pages/change-password")
);
const ForgotPasswordPage = lazy(() =>
  import("../../features/auth/pages/forgot-password")
);
const ResetPasswordPage = lazy(() =>
  import("../../features/auth/pages/reset-password")
);
const HomePage = lazy(() => import("../../pages/home"));
const PubEditPage = lazy(() => import("../../features/pub/pages/edit"));
const DepartmentFormPage = lazy(() =>
  import("../../features/department/pages/form")
);
const DepartmentListPage = lazy(() =>
  import("../../features/department/pages/list")
);
const MenuListPage = lazy(() => import("../../features/menu/pages/list"));
const MenuGroupFormPage = lazy(() =>
  import("../../features/menu/pages/group-form")
);
const MenuProductFormPage = lazy(() =>
  import("../../features/menu/pages/product-form")
);
const MenuComplementFormPage = lazy(() =>
  import("../../features/menu/pages/complement-form")
);
const SaleListPage = lazy(() => import("../../features/sale/pages/list"));
const SaleFormPage = lazy(() => import("../../features/sale/pages/form"));
const PrivacyPolicyPage = lazy(() => import("../privacy-policy"));
const TermsPage = lazy(() => import("../terms-and-conditions"));
const EmailConfirmed = lazy(() => import("../email-confirmed"));
const NotFound = lazy(() => import("../not-found"));

const ServicePage = lazy(() => import("../../features/table/pages/list"));

const BartenderFormListPage = lazy(() =>
  import("../../features/bartender-form/pages/list")
);
const BartenderFormPage = lazy(() =>
  import("../../features/bartender-form/pages/form")
);
const ProductListPage = lazy(() =>
  import("../../features/menu/pages/product-list")
);

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    flex: 0,
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    zIndex: 2,
  },
  nav: {
    display: "flex",
    minWidth: 180,
  },
  content: {
    display: "flex",
    flex: 1,
  },
  main: {
    flex: 1,
  },
});

const PrivateRoute: React.SFC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const [auth] = useContext(AuthContainer.Context);
  const classes = useStyles();

  if (!Component) {
    return null;
  }

  if (auth.loading) {
    return <Loading />;
  }

  if (!auth.isLoggedIn && rest.path !== "/auth/sign-in") {
    return (
      <Redirect
        to={{
          pathname: "/auth/sign-in",
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps<{}>) => (
        <div className={classes.root}>
          <header className={classes.header}>
            <Header />
          </header>
          <div className={classes.content}>
            <nav className={classes.nav}>
              <Navigation />
            </nav>
            <main className={classes.main}>
              <Component {...props} />
            </main>
          </div>
        </div>
      )}
    />
  );
};

function Container() {
  return (
    <Switch>
      <PrivateRoute path="/" exact component={HomePage} />
      <Route path="/erro-404" exact component={NotFound} />
      <Route path="/email-confirmado" exact component={EmailConfirmed} />
      <Route path="/termos-e-condicoes" exact component={TermsPage} />
      <Route
        path="/politica-de-privacidade"
        exact
        component={PrivacyPolicyPage}
      />
      <Route path="/auth/sign-in" exact component={LoginPage} />
      <Route
        path="/profile/change-password"
        exact
        component={ChangePasswordPage}
      />
      <Route
        path="/auth/forgot-password"
        exact
        component={ForgotPasswordPage}
      />
      <Route path="/auth/reset-password" exact component={ResetPasswordPage} />
      <PrivateRoute path="/pub" exact component={PubEditPage} />
      <PrivateRoute path="/department" exact component={DepartmentListPage} />
      <PrivateRoute
        path="/department/edit/:id"
        exact
        component={DepartmentFormPage}
      />
      <PrivateRoute
        path="/department/insert"
        exact
        component={DepartmentFormPage}
      />

      <PrivateRoute path="/menu" exact component={MenuListPage} />
      <PrivateRoute
        path="/menu/group/insert"
        exact
        component={MenuGroupFormPage}
      />
      <PrivateRoute
        path="/menu/group/:parentId/insert"
        exact
        component={MenuGroupFormPage}
      />
      <PrivateRoute
        path="/menu/group/edit/:id"
        exact
        component={MenuGroupFormPage}
      />

      <PrivateRoute
        path="/menu/product/insert"
        exact
        component={MenuProductFormPage}
      />
      <PrivateRoute
        path="/menu/product/:parentId/insert"
        exact
        component={MenuProductFormPage}
      />
      <PrivateRoute
        path="/menu/product/edit/:id"
        exact
        component={MenuProductFormPage}
      />

      <PrivateRoute
        path="/menu/:type/complement/:parentId/insert"
        exact
        component={MenuComplementFormPage}
      />
      <PrivateRoute
        path="/menu/:type/complement/:parentId/edit/:id"
        exact
        component={MenuComplementFormPage}
      />

      <PrivateRoute path="/product-list" exact component={ProductListPage} />

      <PrivateRoute path="/sale" exact component={SaleListPage} />
      <PrivateRoute path="/sale/insert" exact component={SaleFormPage} />
      <PrivateRoute path="/sale/edit/:id" exact component={SaleFormPage} />

      <PrivateRoute
        path="/bartender-form"
        exact
        component={BartenderFormListPage}
      />
      <PrivateRoute
        path="/bartender-form/insert"
        exact
        component={BartenderFormPage}
      />
      <PrivateRoute
        path="/bartender-form/edit/:id"
        exact
        component={BartenderFormPage}
      />

      <PrivateRoute path="/service" exact component={ServicePage} />
    </Switch>
  );
}

export default Container;
