import Dinero from "dinero.js";

export function dinero(amount: number = 0) {
  return Dinero({ amount: Math.floor(amount), currency: "BRL" }).setLocale(
    "pt-BR"
  );
}

export function dineroFromNumber(amount: number = 0) {
  return Dinero({
    amount: Math.round(amount * 100),
    currency: "BRL"
  }).setLocale("pt-BR");
}
