import { User } from "./models";
import { firestore } from "../../firebase";

export async function getUserData(userId: string) {
  const response = await firestore
    .collection("user")
    .doc(userId)
    .get();

  return response.data() as User;
}

export async function setUserPub(pubId: string) {
  return localStorage.setItem("pubId", pubId);
}
