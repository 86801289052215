export enum PaymentFormType {
  Money = "ESPECIE",
  Credit = "CREDITO",
  Debit = "DEBITO",
  MealTicket = "VALE"
}

export enum PaymentFormStatus {
  Active = "A",
  Inactive = "I"
}

export type PaymentForm = {
  readonly uid: string;
  readonly name: string;
  readonly paymentFormsType: PaymentFormType;
};

export interface PaymentFormMap {
  [s: string]: boolean;
}
