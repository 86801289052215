import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { useTranslation } from "react-i18next/hooks";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
}

function SimpleDialog(props: Props) {
  const [t] = useTranslation(["common"]);

  return (
    <Dialog
      onBackdropClick={props.onClose}
      onEscapeKeyDown={props.onClose}
      open={props.open}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {t("common:::No")}
        </Button>
        <Button onClick={props.onConfirm} color="primary" autoFocus>
          {t("common:::Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SimpleDialog;
