import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/app";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://36432315ef1a41d2bf24d45301265944@sentry.io/1464845"
  });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
