import React from "react";
import { CircularProgress, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,.3)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  center: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  loading: {
    padding: 8,
    width: 64,
    height: 64,
    background: "#fff",
    borderRadius: "50%"
  }
}));

export function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.loading}>
        <CircularProgress size={48} />
      </div>
    </div>
  );
}

export function CenterLoading() {
  const classes = useStyles();

  return (
    <div className={classes.center}>
      <div className={classes.loading}>
        <CircularProgress size={48} />
      </div>
    </div>
  );
}
