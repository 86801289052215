import React from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { fieldToSelect, SelectProps } from "formik-material-ui";

interface FormikProps extends SelectProps {
  label?: string;
}
const FormikCustomSelect = (props: FormikProps) => (
  <FormControl style={{ width: "100%" }}>
    {props.label && <InputLabel htmlFor={props.name}>{props.label}</InputLabel>}
    <Select {...fieldToSelect(props)}>{props.children}</Select>
  </FormControl>
);

export { FormikCustomSelect };
