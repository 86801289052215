import React from "react";
import { TextField } from "@material-ui/core";
import { fieldToTextField, TextFieldProps } from "formik-material-ui";

const FormikCustomTextField = (props: TextFieldProps) => {
  const {
    field: { value = "" }
  } = props;
  const length = typeof value === "string" ? value.length : 0;

  return (
    <TextField
      {...fieldToTextField(props)}
      style={{ width: "100%" }}
      helperText={
        props.inputProps && props.inputProps.maxLength
          ? `${length}/${props.inputProps.maxLength} caracteres`
          : null
      }
    />
  );
};

export { FormikCustomTextField };
