import React from "react";
import { TextField } from "@material-ui/core";
import InputMask, { MaskOptions } from "react-input-mask";
import { fieldToTextField, TextFieldProps } from "formik-material-ui";
import { getIn } from "formik";

interface MaskProps extends TextFieldProps, MaskOptions {}
const FormikMaskInput = (props: MaskProps) => {
  const fieldError = getIn(props.form.errors, props.field.name);
  const showError = getIn(props.form.touched, props.field.name) && !!fieldError;

  const { mask, formatChars, maskChar, ...rest } = props;
  const fieldProps = fieldToTextField(rest);

  return (
    <InputMask
      mask={mask}
      formatChars={formatChars}
      maskChar={maskChar}
      value={props.field.value}
      onChange={props.field.onChange}
      disabled={props.disabled || props.form.isSubmitting}
      onBlur={props.field.onBlur}
      style={{ width: "100%" }}
    >
      {(inputProps: any) => {
        return (
          <TextField
            name={props.field.name}
            error={showError}
            {...fieldProps}
            {...inputProps}
          />
        );
      }}
    </InputMask>
  );
};

export { FormikMaskInput };
