import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next/hooks";

const basePath = process.env.REACT_APP_BASE_PATH || "";
const isDebug = process.env.NODE_ENV === "development";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "pt-BR",

    // have a common namespace used around the full app
    ns: ["common"],
    defaultNS: "common",

    nsSeparator: ":::",
    keySeparator: "::",

    debug: isDebug,

    interpolation: {
      escapeValue: false // not needed for react!!
    },

    react: {
      wait: true
    },

    backend: {
      loadPath: `${basePath}/locales/{{lng}}/{{ns}}.json`
    }
  });

const language = localStorage.getItem("authLanguage");

if (language) {
  i18n.changeLanguage(language);
}

export default i18n;
