import React from "react";
import { TextField } from "@material-ui/core";
import CurrencyInput from "react-currency-input";
import { fieldToTextField, TextFieldProps } from "formik-material-ui";

function FormikCustomCurrencyInput(props: any) {
  const { inputRef, onChange, ...rest } = props;
  return (
    <CurrencyInput
      style={{ textAlign: "right" }}
      decimalSeparator=","
      thousandSeparator="."
      precision="2"
      prefix="R$ "
      onChangeEvent={(event: any, maskedValue: any, floatValue: any) => {
        onChange(event);
      }}
      {...rest}
    />
  );
}

const FormikCustomCurrencyField = (props: TextFieldProps) => {
  return (
    <TextField
      {...fieldToTextField(props)}
      style={{ width: "100%" }}
      InputProps={{
        inputComponent: FormikCustomCurrencyInput
      }}
    />
  );
};

export { FormikCustomCurrencyField };
