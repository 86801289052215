import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";

const env = process.env.REACT_APP_ENV || "development";

const prodConfig = {
  apiKey: "AIzaSyBBSRPrFQxHaebiOq5BMEkqXh98Ejl35IM",
  authDomain: "onpub-pdv-dev.firebaseapp.com",
  databaseURL: "https://onpub-pdv-dev.firebaseio.com",
  projectId: "onpub-pdv-dev",
  storageBucket: "onpub-pdv-dev.appspot.com",
  messagingSenderId: "483672490238",
  appId: "1:483672490238:web:69bec318d28c24dd7ea5af",
  measurementId: "G-3FJ150YN28"
};

const devConfig = {
  apiKey: "AIzaSyBBSRPrFQxHaebiOq5BMEkqXh98Ejl35IM",
  authDomain: "onpub-pdv-dev.firebaseapp.com",
  databaseURL: "https://onpub-pdv-dev.firebaseio.com",
  projectId: "onpub-pdv-dev",
  storageBucket: "onpub-pdv-dev.appspot.com",
  messagingSenderId: "483672490238",
  appId: "1:483672490238:web:69bec318d28c24dd7ea5af",
  measurementId: "G-3FJ150YN28"
};

const stagingConfig = {
  apiKey: "AIzaSyBBSRPrFQxHaebiOq5BMEkqXh98Ejl35IM",
  authDomain: "onpub-pdv-dev.firebaseapp.com",
  databaseURL: "https://onpub-pdv-dev.firebaseio.com",
  projectId: "onpub-pdv-dev",
  storageBucket: "onpub-pdv-dev.appspot.com",
  messagingSenderId: "483672490238",
  appId: "1:483672490238:web:69bec318d28c24dd7ea5af",
  measurementId: "G-3FJ150YN28"
};

function getConfig(env: string) {
  switch (env) {
    case "production":
      return prodConfig;
    case "development":
      return devConfig;
    case "staging":
      return stagingConfig;
    default:
      return devConfig;
  }
}

const config = getConfig(env);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const firebaseAuth = firebase.auth();
const firebaseStorage = firebase.storage();
const firestore = firebase.firestore();
const firebaseFunctions = firebase.functions();

interface ObjectMap<T> {
  [s: string]: T;
}
export function snapshotToObject<T>(
  snapshot: firebase.firestore.QuerySnapshot
) {
  let obj: ObjectMap<T> = {};

  snapshot.forEach(doc => {
    if (doc.exists) {
      obj[doc.id] = doc.data() as T;
    }
  });

  return obj;
}

export {
  firebase,
  firebaseAuth,
  firebaseStorage,
  firestore,
  firebaseFunctions
};
