import React from "react";
import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/styles";

interface Props extends TableCellProps {}

const useStyles = makeStyles({
  head: {
    fontWeight: 500,
    fontSize: 13
  },
  body: {
    fontSize: 13
  }
});

function CustomTableCellWithProps(props: Props) {
  const classes = useStyles({});
  return <TableCell {...props} classes={classes} />;
}

export default CustomTableCellWithProps;
