import React, { useContext, useState } from "react";
import {
  Theme,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import logo from "../../img/logo.png";
import UserIcon from "@material-ui/icons/PermIdentity";
import { UserPubMap } from "../../features/auth/models";
import { setUserPub } from "../../features/auth/api";
import { firebaseAuth } from "../../firebase";
import { withRouter, RouteComponentProps } from "react-router";
import { useCurrentPub, useCurrentPubId } from "../../features/pub/state";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next/hooks";
import AuthContainer from "../../features/auth/state";
import { confirmableSnackbar } from "../snackbar";

interface Props extends RouteComponentProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    background: "#fff",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  logo: {
    height: 64
  },

  pub: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  changePubButton: {
    marginRight: theme.spacing(2)
  },
  pubLogo: {
    width: 64,
    height: 64,
    marginRight: theme.spacing(2)
  },

  user: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  userIcon: {
    width: 32,
    height: 32
  }
}));

function Header(props: Props) {
  const [auth] = useContext(AuthContainer.Context);
  const [modalOpen, setModalOpen] = useState(false);
  const [menu, setMenu] = useState(null as null | (EventTarget & HTMLElement));
  const pub = useCurrentPub();
  const currentPubId = useCurrentPubId();
  const classes = useStyles();
  const [t] = useTranslation(["common", "header"]);

  if (!auth.user) {
    return null;
  }

  const hasPubs = Object.keys(auth.user.pubs).length > 1;

  if (!pub) {
    return null;
  }

  async function handleModalClose(pubId: string | null) {
    if (!pubId) {
      setModalOpen(false);
      return false;
    }

    if (currentPubId === pubId) {
      setModalOpen(false);
      return false;
    }

    try {
      await setUserPub(pubId);
      window.location.reload();
    } catch (e) {
      confirmableSnackbar("Error trying to change pub", "error");
      setModalOpen(false);
    }
  }

  function handleChangePassword() {
    props.history.push("/profile/change-password");
    setMenu(null);
  }

  function handleLogout() {
    setMenu(null);
    firebaseAuth.signOut();
  }

  return (
    <div className={classes.root}>
      <figure>
        <img src={logo} alt="On Pub" className={classes.logo} />
      </figure>
      <div className={classes.pub}>
        {hasPubs && (
          <Button
            variant="outlined"
            className={classes.changePubButton}
            onClick={() => setModalOpen(true)}
          >
            {t("common:::Change")}
          </Button>
        )}
        {pub.image && <Avatar src={pub.image} className={classes.pubLogo} />}
        <div>
          <Typography variant="h5">{pub.alias}</Typography>
          <Typography variant="subtitle1">{pub.district}</Typography>
        </div>
      </div>
      <div className={classes.user}>
        <IconButton onClick={e => setMenu(e.currentTarget)}>
          <UserIcon className={classes.userIcon} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={menu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={menu !== null}
          onClose={() => setMenu(null)}
        >
          <MenuItem onClick={handleChangePassword}>
            {t("header:::Change password")}
          </MenuItem>
          <MenuItem onClick={handleLogout}>{t("header:::Logout")}</MenuItem>
        </Menu>

        <Typography variant="h6">{auth.user.name}</Typography>
      </div>
      {modalOpen && (
        <ChoosePubDialog
          pubs={auth.user.pubs}
          open={modalOpen}
          onClose={handleModalClose}
          selectedValue={auth.pubId}
        />
      )}
    </div>
  );
}

interface DialogProps {
  open: boolean;
  onClose: (pubId: string | null) => void;
  selectedValue: string | null;
  pubs: UserPubMap;
}
function ChoosePubDialog(props: DialogProps) {
  const [t] = useTranslation(["header"]);

  function handleClose() {
    props.onClose(props.selectedValue);
  }

  function handleListItemClick(pubId: string) {
    props.onClose(pubId);
  }

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle id="simple-dialog-title">
        {t("header:::Select restaurant")}
      </DialogTitle>
      <div>
        <List>
          {Object.entries(props.pubs).map(([pubId, userPub]) => (
            <ListItem
              button
              onClick={() => handleListItemClick(pubId)}
              key={pubId}
            >
              <ListItemAvatar>
                <Avatar src={userPub.image} />
              </ListItemAvatar>
              <ListItemText
                primary={`${userPub.alias} - ${userPub.district}`}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </Dialog>
  );
}

export default withRouter(Header);
