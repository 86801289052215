import React from "react";
import { Theme, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: `calc(100vh - 80px - 68px - ${theme.spacing(6)}px)`
  }
}));

function CustomCard(props: Props) {
  const classes = useStyles();

  return <Card className={classes.card}>{props.children}</Card>;
}

export default CustomCard;
