import React from "react";
import * as Sentry from "@sentry/browser";

type State = {
  hasError: boolean;
};

type Props = {};

export default class ExampleBoundary extends React.Component<Props, State> {
  state: State = { hasError: false };

  componentDidCatch(error: any) {
    this.setState({ hasError: true });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Ocorreu um erro, tente novamente</h1>;
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
