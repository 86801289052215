import { firestore } from "firebase";

export enum FirebaseOrderSituation {
  Pending = "P",
  Completed = "A",
  Cancelled = "C",
  Started = "I"
}

export enum OrderSituation {
  Pending = "P",
  Completed = "A",
  Cancelled = "C",
  Started = "I",
  Late = "L"
}

export enum OrderFilter {
  Pending = "P",
  Completed = "A",
  Cancelled = "C",
  All = "T"
}

export enum ServiceSituation {
  Pending = "P",
  Active = "A",
  Completed = "F",
  Cancelled = "C",
  Started = "I"
}

export enum PaymentSituation {
  Pending = "P",
  Completed = "E"
}

export enum ServiceType {
  Balcony = "B",
  Bartender = "G",
  Table = "M"
}

export enum UserType {
  Bartender = "G",
  Client = "C",
  PubOwner = "P"
}

export enum UserSituation {
  Active = "A",
  Inactive = "I"
}

export enum BalconySituation {
  Pending = "P",
  Completed = "A",
  Cancelled = "C",
  Started = "I"
}

export enum ComplementType {
  Additional = "A",
  Product = "P",
  Size = "T"
}

export enum OrderStatus {
  Pending = "P",
  Active = "A",
  Completed = "C"
}

export type Service = Readonly<{
  key: string;
  date: firestore.Timestamp;
  departmentName: string | null;
  tableNumber: number | null;
  situation: ServiceSituation;
  type: ServiceType;
  orders: Order[];
  payments: Payment[];
  users: FirestoreUser[];
  tableId: string | null;
  departmentId: string | null;
  userId: string | null;
  bartenderId: string | null;
  serviceNumber: string | null;
  total: Dinero.Dinero;
  paid: Dinero.Dinero;
}>;

export interface ServiceMap {
  [s: string]: Service;
}

export type OrderComplement = Readonly<{
  key: string;
  name: string;
  type: ComplementType;
  itens: OrderComplementItem[];
}>;

export type OrderComplementItem = Readonly<{
  key: string;
  quantity: number;
  price: number;
  name: string;
  productId?: string | null;
  quantityFree: number;
}>;

export type OrderFlavour = Readonly<{
  key: string;
  productName: string;
  integrationId: string | null;
  quantity: number;
  price: number;
  complements: OrderComplement[];
}>;

export interface OrderFlavourMap {
  [s: string]: OrderFlavour;
}

export type Order = Readonly<{
  tableNumber: number | null;
  orderNumber: number;
  orderId: string;
  startDate: firestore.Timestamp;
  endDate: firestore.Timestamp | null;
  orderStatus: OrderSituation | null;
  itens: OrderItem[];
  userId: string | null;
  total: Dinero.Dinero;
  serviceId: string;
  userName: string;
  status: OrderStatus;
}>;

export interface OrderMap {
  [s: string]: Order;
}

export type OrderItem = Readonly<{
  tableNumber: number | null;
  startDate: firestore.Timestamp;
  endDate: firestore.Timestamp | null;
  orderStatus: OrderSituation;
  subTotal: Dinero.Dinero;
  total: Dinero.Dinero;
  productId: string;
  productPrice: number;
  productName: string;
  integrationId: string | null;
  observation?: string;
  quantity: number;
  complements: OrderComplement[];
  userId: string | null;
  userName: string;
  bartenderId: string | null;
  key: string;
  flavors: OrderFlavour[];
  preparationTime?: number | null;
}>;

export interface OrderItemMap {
  [s: string]: OrderItem;
}

export type Payment = Readonly<{
  paymentDate: firestore.Timestamp;
  paymentTypeName: string | null;
  paymentSituation: PaymentSituation;
  paymentTypeId: string | null;
  paymentId?: string;
  userId: string;
  userName: string;
  amount: Dinero.Dinero;
  serviceTax: Dinero.Dinero;
  discount: Dinero.Dinero;
  coupon: Dinero.Dinero;
  key: string;
  serviceId: string;
}>;

export interface PaymentMap {
  [s: string]: Payment;
}

/** Firebase */
export type FirebaseService = Readonly<{
  datatd: number;
  nomset?: string;
  nummes?: number;
  sitatd: ServiceSituation;
  tipatd: ServiceType;
  tpedatd?: FirebaseOrderMap;
  tpagped?: FirebasePaymentMap;
  tusuatd?: FirebaseUserMap;
  uidmes?: string;
  uidset?: string;
  uiduat?: string;
  uidusu?: string;
  numatd?: string;
}>;

export interface FirebaseServiceMap {
  [s: string]: FirebaseService;
}

export type FirebaseOrder = Readonly<{
  numped: number;
  datped: number;
  uidusu?: string;
  uiduat?: string;
  tipdped: FirebaseOrderItemMap;
}>;

export interface FirebaseOrderMap {
  [s: string]: FirebaseOrder;
}

export type FirebaseOrderItem = Readonly<{
  datprv: number;
  uidpro: string;
  nompro: string;
  proqtd: number;
  proval: number;
  staite: OrderSituation;
  iteenv?: string;
  proobs?: string | null;
  teminf?: number | null;
  tprocpl?: FirebaseOrderComplementMap;
  tprosab?: FirebaseOrderFlavourMap;
}>;

export interface FirebaseOrderItemMap {
  [s: string]: FirebaseOrderItem;
}

export type FirebasePayment = Readonly<{
  datpag: number;
  nomfpg: string;
  sitpag: PaymentSituation;
  uidfpg?: string;
  uidusu: string;
  uidpag?: string;
  valpag: number;
  valtax: number;
  valdcs: number;
  valcup: number;
}>;

export interface FirebasePaymentMap {
  [s: string]: FirebasePayment;
}

export type FirebaseUser = Readonly<{
  catusu: UserType;
  logusu: string;
  nomusu: string;
  fotusu?: string;
  situsu: UserSituation;
}>;

export interface FirebaseUserMap {
  [s: string]: FirebaseUser;
}

export type FirebaseOrderComplement = Readonly<{
  nomcpl: string;
  tipcpl: ComplementType;
  tpcpite?: FirebaseOrderComplementItemMap;
}>;

export interface FirebaseOrderComplementMap {
  [s: string]: FirebaseOrderComplement;
}

export type FirebaseOrderComplementItem = Readonly<{
  cplqtd: number;
  cplval: number;
  nomicp: string;
  proicp?: string | null;
  qtdgts: number;
}>;

export interface FirebaseOrderComplementItemMap {
  [s: string]: FirebaseOrderComplementItem;
}

export type FirebaseOrderFlavour = Readonly<{
  proobs?: string;
  nompro: string;
  proqtd: number;
  proval: number;
  tprocpl?: FirebaseOrderComplementMap;
}>;

export interface FirebaseOrderFlavourMap {
  [s: string]: FirebaseOrderFlavour;
}

export enum BartenderCallReason {
  Service = "A",
  Payment = "P",
  LeaveTable = "T"
}

export enum BartenderCallStatus {
  Pending = "P",
  Completed = "A"
}

export type FirebaseBartenderCall = Readonly<{
  datatd: number;
  motatd: BartenderCallReason;
  sitatd: BartenderCallStatus;
  uidatd: string;
  uidmes: string;
  uidset: string;
  uidusu: string;
  nomusu: string;
  valpag?: number | null;
  nummes?: number | null;
}>;

type BaseService = {
  departmentName: string | null;
  tableNumber: number | null;
  situation: ServiceSituation;
  type: ServiceType;
  tableId: string | null;
  departmentId: string | null;
  userId: string | null;
  bartenderId: string | null;
  serviceNumber: string | null;
  total: number;
  paid: number;
  pubId: string;
  pubImage: string | null;
  pubName: string;
  currentBartenderId: string | null;
};

export interface FirestoreInputService extends BaseService {
  date: firebase.firestore.FieldValue;
}

export interface FirestoreService extends BaseService {
  date: firestore.Timestamp;
}

export interface FirestoreServiceMap {
  [s: string]: FirestoreService;
}

type BaseFirestorePayment = Readonly<{
  paymentTypeName: string | null;
  paymentSituation: PaymentSituation;
  paymentTypeId: string | null;
  userId: string;
  userName: string;
  amount: number;
  serviceTax: number;
  discount: number;
  coupon: number;
  integrationId: string | null;
  couponId: string | null;
  couponNotificationUid: string | null;
  couponName: string | null;
  couponCode: string | null;
  pubId: string;
  serviceId: string;
}>;

export interface FirestoreInputPayment extends BaseFirestorePayment {
  paymentDate: firebase.firestore.FieldValue;
}

export interface FirestorePayment extends BaseFirestorePayment {
  paymentDate: firestore.Timestamp;
}

export interface FirestorePaymentMap {
  [s: string]: FirestorePayment;
}

type FirestoreBaseOrder = Readonly<{
  orderNumber: number;
  bartenderId: string | null;
  userId: string | null;
  itens: CartItemMap;
  serviceId: string;
  pubId: string;
  tableNumber: number | null;
  userName: string;
  userPhoto: string | null;
  total: number;
  status: OrderStatus;
}>;

export interface FirestoreOrder extends FirestoreBaseOrder {
  startDate: firestore.Timestamp;
}

export interface FirestoreOrderMap {
  [s: string]: FirestoreOrder;
}

export interface FirestoreInputOrder extends FirestoreBaseOrder {
  startDate: firebase.firestore.FieldValue;
}

export type CartItem = Readonly<{
  endDate?: firestore.Timestamp;
  productId: string;
  productName: string;
  quantity: number;
  price: number;
  total: number;
  orderStatus: OrderSituation;
  observation?: string | null;
  preparationTime?: number | null;
  complements?: CartComplementMap;
  flavors?: CartFlavourMap;
  integrationId: string | null;
}>;

export interface CartItemMap {
  [s: string]: CartItem;
}

export type CartComplementItem = Readonly<{
  quantity: number;
  price: number;
  name: string;
  productId?: string | null;
  quantityFree: number;
}>;

export interface CartComplementItemMap {
  [s: string]: CartComplementItem;
}

export type CartComplement = Readonly<{
  name: string;
  type: ComplementType;
  complementItens?: CartComplementItemMap;
}>;

export interface CartComplementMap {
  [s: string]: CartComplement;
}

export type CartFlavour = Readonly<{
  integrationId: string | null;
  productName: string;
  productId: string;
  quantity: number;
  price: number;
  complements?: CartComplementMap;
}>;

export interface CartFlavourMap {
  [s: string]: CartFlavour;
}

export type FirestoreUser = Readonly<{
  userType: UserType;
  login: string;
  name: string;
  photo: string | null;
  serviceId: string | null;
  pubId: string;
  departmentId: string | null;
  tableId: string | null;
  tableNumber: number | null;
  serviceType: ServiceType;
}>;

export interface FirestoreUserMap {
  [s: string]: FirestoreUser;
}

export type OrderListItem = Readonly<{
  userName: string;
  user: FirestoreUser | null;
  order: Order;
  serviceId: string;
  key: string;
  date: firestore.Timestamp;
  departmentName: string | null;
  tableNumber: number | null;
  situation: ServiceSituation;
  type: ServiceType;
  tableId: string | null;
  departmentId: string | null;
  userId: string | null;
  bartenderId: string | null;
  serviceNumber: string | null;
  total: number;
  payments: PaymentMap;
}>;
