import React from "react";
import { CardActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles({
  actions: {
    display: "flex",
    justifyContent: "flex-end"
  }
});

function CustomCardActions(props: Props) {
  const classes = useStyles();

  return (
    <CardActions className={classes.actions}>{props.children}</CardActions>
  );
}

export default CustomCardActions;
