import { firebase, firestore, firebaseFunctions } from "../../firebase";
import {
  Order,
  Payment,
  PaymentMap,
  Service,
  OrderSituation,
  OrderItem,
  OrderComplement,
  OrderComplementItem,
  OrderFlavour,
  PaymentSituation,
  FirestoreInputPayment,
  FirestoreOrder,
  CartComplement,
  FirestorePayment,
  FirestoreService,
  OrderMap,
  FirestoreUserMap,
  OrderStatus,
  ServiceSituation
} from "./models";
import { addMinutes } from "date-fns";
import { PaymentForm } from "../payment-form/models";
import { User } from "../auth/models";
import { getPaymentFormName } from "../payment-form/api";
import { FirestoreTable } from "../table/models";
import { dinero, dineroFromNumber } from "../../utils/dinero";

export const getActiveServicesRef = (pubId: string) =>
  firestore
    .collection("services")
    .where("pubId", "==", pubId)
    .where("situation", "==", ServiceSituation.Active);

export const getOrdersRef = (pubId: string) =>
  firestore
    .collection("orders")
    .where("pubId", "==", pubId)
    .where("status", "==", OrderStatus.Active);

export const getPaymentsRef = (pubId: string) =>
  firestore.collection("payments").where("pubId", "==", pubId);

export const getUsersRef = (pubId: string) =>
  firestore.collection("users").where("pubId", "==", pubId);

function getPriority(status: OrderSituation) {
  switch (status) {
    case OrderSituation.Started:
      return 1;
    case OrderSituation.Late:
      return 2;
    case OrderSituation.Pending:
      return 3;
    case OrderSituation.Completed:
      return 4;
    case OrderSituation.Cancelled:
      return 5;
    default:
      return 0;
  }
}

export const convertComplement = (
  complementId: string,
  fbComplement: CartComplement
) => {
  const { complementItens = {} } = fbComplement;

  const complement: OrderComplement = {
    key: complementId,
    name: fbComplement.name,
    type: fbComplement.type,
    itens: Object.entries(complementItens).map(
      ([complementItemId, fbComplementItem]) => {
        const complementItem: OrderComplementItem = {
          key: complementItemId,
          quantity: fbComplementItem.quantity,
          price: fbComplementItem.price,
          name: fbComplementItem.name,
          productId: fbComplementItem.productId,
          quantityFree: fbComplementItem.quantityFree
        };

        return complementItem;
      }
    )
  };

  return complement;
};

export const convertOrder = (orderId: string, order: FirestoreOrder) => {
  const userId = order.userId || order.bartenderId || null;
  const { itens = {} } = order;

  const convertedItens = Object.entries(itens).map(
    ([orderItemId, orderItem]) => {
      const { complements = {}, flavors = {} } = orderItem;

      const convertedComplements = Object.entries(complements).map(
        ([complementId, fbComplement]) => {
          const complement = convertComplement(complementId, fbComplement);
          return complement;
        }
      );

      const convertedFlavors = Object.entries(flavors).map(
        ([flavourId, fbFlavour]) => {
          const { complements = {} } = fbFlavour;
          const flavourComplements = Object.entries(complements).map(
            ([complementId, fbComplement]) => {
              const complement = convertComplement(complementId, fbComplement);
              return complement;
            }
          );

          const flavour: OrderFlavour = {
            key: flavourId,
            productName: fbFlavour.productName,
            quantity: fbFlavour.quantity,
            price: fbFlavour.price,
            complements: flavourComplements,
            integrationId: fbFlavour.integrationId
          };

          return flavour;
        }
      );

      const convertedOrderItem: OrderItem = {
        tableNumber: order.tableNumber,
        startDate: order.startDate,
        endDate: orderItem.endDate || null,
        orderStatus: orderItem.orderStatus || OrderSituation.Pending,
        integrationId: orderItem.integrationId,
        productId: orderItem.productId,
        productPrice: orderItem.price || 0,
        productName: orderItem.productName,
        observation: orderItem.observation || undefined,
        quantity: orderItem.quantity || 1,
        complements: convertedComplements,
        userId,
        userName: order.userName,
        bartenderId: order.bartenderId,
        key: orderItemId,
        flavors: convertedFlavors,
        subTotal: dinero(orderItem.total / orderItem.quantity),
        total: dinero(orderItem.total),
        preparationTime: orderItem.preparationTime
      };
      return convertedOrderItem;
    }
  );

  const sortedItens = convertedItens.sort((item1, item2) => {
    return getPriority(item1.orderStatus) - getPriority(item2.orderStatus);
  });

  const firstItem = sortedItens.find(item => !!item);

  const convertedOrder: Order = {
    tableNumber: order.tableNumber,
    orderNumber: order.orderNumber,
    orderId,
    orderStatus: firstItem ? firstItem.orderStatus : null,
    startDate: firstItem ? firstItem.startDate : order.startDate,
    endDate: firstItem ? firstItem.endDate : null,
    itens: sortedItens,
    userId,
    total: dinero(order.total),
    serviceId: order.serviceId,
    userName: order.userName,
    status: order.status
  };

  return convertedOrder;
};

export const convertPayment = (
  paymentId: string,
  payment: FirestorePayment
) => {
  const convertedPayment: Payment = {
    ...payment,
    key: paymentId,
    amount: dinero(payment.amount),
    serviceTax: dinero(payment.serviceTax),
    discount: dinero(payment.discount),
    coupon: dinero(payment.coupon)
  };

  return convertedPayment;
};

export const convertService = (
  serviceId: string,
  service: FirestoreService,
  orders: OrderMap,
  payments: PaymentMap,
  users: FirestoreUserMap
) => {
  const dbService: Service = {
    ...service,
    paid: dinero(service.paid),
    total: dinero(service.total),
    key: serviceId,
    orders: Object.entries(orders)
      .filter(([orderId, order]) => order.serviceId === serviceId)
      .map(([orderId, order]) => order),
    payments: Object.entries(payments)
      .filter(([orderId, order]) => order.serviceId === serviceId)
      .map(([orderId, order]) => order),
    users: Object.entries(users)
      .filter(([orderId, order]) => order.serviceId === serviceId)
      .map(([orderId, order]) => order)
  };

  return dbService;
};

export const cancelOrderItem = async (
  orderId: string,
  orderItem: OrderItem
) => {
  const itemPath = `itens.${orderItem.key}.orderStatus`;
  return firestore
    .collection("orders")
    .doc(orderId)
    .update({
      [itemPath]: OrderSituation.Cancelled
    });
};

export const confirmOrderItem = async (
  orderId: string,
  orderItem: OrderItem
) => {
  const itemPath = `itens.${orderItem.key}.orderStatus`;
  return firestore
    .collection("orders")
    .doc(orderId)
    .update({
      [itemPath]: OrderSituation.Completed
    });
};

export const startOrderItem = async (orderId: string, orderItem: OrderItem) => {
  let updates: UpdateMap = {};

  const statusPath = `itens.${orderItem.key}.orderStatus`;
  const prepPath = `itens.${orderItem.key}.preparationTime`;

  updates[statusPath] = OrderSituation.Started;

  if (orderItem.preparationTime) {
    const serverTime = new Date();
    const prepTime = addMinutes(serverTime, orderItem.preparationTime);
    updates[prepPath] = prepTime;
  }

  return firestore
    .collection("orders")
    .doc(orderId)
    .update(updates);
};

export interface UpdateMap {
  [s: string]: any;
}

export const addBartenderPayment = async (
  userId: string,
  pubId: string,
  serviceId: string,
  paymentForm: PaymentForm,
  amount: number,
  discount: number,
  tax: number,
  userData: User
) => {
  const payment: FirestoreInputPayment = {
    serviceTax: dineroFromNumber(tax).getAmount(),
    amount: dineroFromNumber(amount).getAmount(),
    discount: dineroFromNumber(discount).getAmount(),
    coupon: 0,
    userId,
    userName: userData.name,
    paymentDate: firebase.firestore.FieldValue.serverTimestamp(),
    paymentSituation: PaymentSituation.Completed,
    paymentTypeId: paymentForm.uid,
    paymentTypeName: getPaymentFormName(paymentForm),
    integrationId: null,
    couponCode: null,
    couponId: null,
    couponName: null,
    couponNotificationUid: null,
    pubId,
    serviceId
  };

  return firestore.collection("payments").add(payment);
};

export const confirmBartenderPayment = async (
  pubId: string,
  serviceId: string,
  payment: Payment,
  paymentForm: PaymentForm
) => {
  return firestore
    .collection("payments")
    .doc(payment.key)
    .update({
      paymentSituation: PaymentSituation.Completed
    });
};

export const releaseTable = async (serviceId: string) => {
  const finalizeTableApp = firebaseFunctions.httpsCallable("finalizeTableApp");
  return finalizeTableApp({ serviceId });
};

export const transferTable = async (
  table: FirestoreTable,
  newTable: FirestoreTable
) => {
  const transferTableApp = firebaseFunctions.httpsCallable("transferTableApp");

  return transferTableApp({
    pubId: table.pubId,
    oldTableNumber: table.number,
    newTableNumber: newTable.number
  });
};

export function getService(services: Service[], orderId: string) {
  const service = services.find(s => {
    const order = s.orders.find(o => o.orderId === orderId);
    return !!order;
  });

  if (!service) {
    return null;
  }

  return service;
}

export function getOrder(service: Service, orderId: string) {
  const order = service.orders.find(o => o.orderId === orderId);

  if (!order) {
    return null;
  }

  return order;
}
