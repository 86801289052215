import React, { useState, useEffect } from "react";
import {
  Theme,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { confirmableSnackbar } from "../snackbar";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next/hooks";

interface Props {
  image: string | null;
  newSaleImage?: Blob | null;
  dropzoneRef?: React.RefObject<Dropzone>;
  onChange: (image: Blob) => void;
  width: number;
  height: number;
}

const initialCropImage: null | string = null;
const initialImageRef: null | HTMLImageElement = null;

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    flex: 1,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100%"
  },
  boxActive: {
    background: "rgb(212, 212, 212)"
  },
  previewImage: {
    maxWidth: "100%"
  }
}));

function ImageCrop(props: Props) {
  const initialCrop = {
    x: 0,
    y: 0,
    width: props.width,
    aspect: props.width / props.height
  } as ReactCrop.Crop;
  const initialPixelCrop = {
    x: 0,
    y: 0,
    width: props.width,
    height: props.height
  } as ReactCrop.PixelCrop;

  const [imageRef, setImageRef] = useState(initialImageRef);
  const [image, setImage] = useState(props.image);
  const [crop, setCrop] = useState(initialCrop);
  const [pixelCrop, setPixelCrop] = useState(initialPixelCrop);
  const [cropImage, setCropImage] = useState(initialCropImage);

  const classes = useStyles();
  const [t] = useTranslation(["common", "image"]);

  useEffect(() => {
    return () => {
      if (cropImage) {
        window.URL.revokeObjectURL(cropImage);
      }
    };
  }, [cropImage]);

  useEffect(() => {
    if (props.newSaleImage) {
      const fileUrl = window.URL.createObjectURL(props.newSaleImage);
      setImage(fileUrl);
    } else if (props.image) {
      setImage(props.image);
    } else {
      setImage(null);
    }
  }, [props.image, props.newSaleImage]);

  function onDrop(files: File[], rejectedFiles: File[]) {
    if (rejectedFiles.length) {
      confirmableSnackbar("Invalid image", "error");
      return false;
    }

    if (files.length === 0) {
      confirmableSnackbar("No image chosen", "error");
      return false;
    }

    const file = files[0];
    const preview = URL.createObjectURL(file);

    var img = new Image();

    img.onload = function() {
      if (img.naturalWidth < props.width || img.naturalHeight < props.height) {
        confirmableSnackbar(
          "Image too small, needs to be at least {{width}}x{{height}} pixels wide",
          "error",
          { width: props.width, height: props.height }
        );
        window.URL.revokeObjectURL(preview);
      } else {
        setCropImage(preview);
      }
    };

    img.src = preview;
  }

  async function saveCroppedImage() {
    try {
      const blob = await getCroppedImg();
      let fileUrl = window.URL.createObjectURL(blob);
      setImage(fileUrl);
      setCropImage(null);
      setCrop(initialCrop);
      setPixelCrop(initialPixelCrop);
      setImageRef(initialImageRef);
      props.onChange(blob);
    } catch (e) {
      confirmableSnackbar("Error trying to save image", "error");
    }
  }

  function onImageLoaded(image: HTMLImageElement) {
    setImageRef(image);
  }

  async function onCropComplete(
    crop: ReactCrop.Crop,
    pixelCrop: ReactCrop.PixelCrop
  ) {
    setPixelCrop(pixelCrop);
  }

  async function getCroppedImg() {
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    if (!ctx || !imageRef) {
      return Promise.reject();
    }

    ctx.drawImage(
      imageRef,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    const rcanvas = document.createElement("canvas");
    rcanvas.width = props.width;
    rcanvas.height = props.height;
    const rctx = rcanvas.getContext("2d");

    if (!rctx) {
      return Promise.reject();
    }

    rctx.drawImage(
      canvas,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      props.width,
      props.height
    );

    return new Promise<Blob>((resolve, reject) => {
      rcanvas.toBlob(blob => {
        if (!blob) {
          reject();
        } else {
          resolve(blob);
        }
      }, "image/jpeg");
    });
  }

  return (
    <>
      {cropImage && (
        <Dialog
          open={true}
          onClose={() => setCropImage(null)}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle>{t("image:::Resize image")}</DialogTitle>
          <DialogContent>
            <ReactCrop
              src={cropImage}
              crop={crop}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={setCrop}
              minWidth={
                imageRef
                  ? (props.width / imageRef.naturalWidth) * 100
                  : undefined
              }
              minHeight={
                imageRef
                  ? (props.height / imageRef.naturalHeight) * 100
                  : undefined
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCropImage(null)} color="primary">
              {t("common:::Back")}
            </Button>
            <Button color="primary" onClick={saveCroppedImage}>
              {t("common:::Save")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dropzone
        accept="image/*"
        multiple={false}
        onDrop={onDrop}
        className={classes.box}
        activeClassName={classNames(classes.box, classes.boxActive)}
        ref={props.dropzoneRef}
      >
        {image ? (
          <img
            src={image}
            alt={t("image:::Loading image...")}
            className={classes.previewImage}
          />
        ) : (
          <Typography variant="body2" align="center">
            {t("image:::Drag and drop your image here")}
          </Typography>
        )}
      </Dropzone>
    </>
  );
}

export default ImageCrop;
