import React, { useState, Suspense } from "react";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { Theme } from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import { theme } from "../../containers/app";
import { useTranslation } from "react-i18next/hooks";
import { Loading } from "../loading";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

interface ContentProps {
  className?: string;
  message: string;
  onClose: () => void;
  variant: "success" | "warning" | "info" | "error";
}

function MySnackbarContent(props: ContentProps) {
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

interface Props {
  message: string;
  onClose: () => void;
  variant: "success" | "warning" | "info" | "error";
}

export default function CustomizedSnackbar(props: Props) {
  const [open, setOpen] = useState(true);

  function handleClose() {
    setOpen(false);
    props.onClose();
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MySnackbarContent
        variant={props.variant}
        message={props.message}
        onClose={handleClose}
      />
    </Snackbar>
  );
}

interface ConfirmableComponentProps {
  message: string;
  params?: any;
  onClose: () => void;
  variant: "success" | "warning" | "info" | "error";
}

function ConfirmableSnackbar(props: ConfirmableComponentProps) {
  const [t] = useTranslation(["error", "success"]);

  function handleClose() {
    props.onClose();
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={true}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MySnackbarContent
        variant={props.variant}
        message={t(`${props.variant}:::${props.message}`, props.params)}
        onClose={handleClose}
      />
    </Snackbar>
  );
}

interface ConfirmableProps {
  confirmation: string;
  variant: "success" | "warning" | "info" | "error";
  params?: any;
}
const createConfirmation = (unmountDelay = 100) => {
  return (props: ConfirmableProps) => {
    const wrapper = document.body.appendChild(document.createElement("div"));

    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading />}>
          <ConfirmableSnackbar
            message={props.confirmation}
            params={props.params}
            variant={props.variant}
            onClose={dispose}
          />
        </Suspense>
      </ThemeProvider>,
      wrapper
    );

    function dispose() {
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(wrapper);
        setTimeout(() => document.body.removeChild(wrapper));
      }, unmountDelay);
    }
  };
};

const confirm = createConfirmation();

const confirmableSnackbar = function(
  confirmation: string,
  variant: "success" | "warning" | "info" | "error",
  params?: any
) {
  return confirm({ confirmation, variant, params });
};

export { confirmableSnackbar };
