import axios from "axios";

let userApi = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: { common: {} }
});

let appApi = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: { common: {} }
});

export { userApi, appApi };
