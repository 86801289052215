import { firebaseStorage, firestore } from "../../firebase";
import { Pub, PubFormApi } from "./models";

export async function getPub(pubId: string) {
  const response = await firestore
    .collection("pub")
    .doc(pubId)
    .get();

  return response.data() as Pub;
}

export async function dbPubUpdate(
  pubId: string,
  pub: PubFormApi
): Promise<PubFormApi> {
  const { newPubImage, ...pubForm } = pub;

  if (newPubImage) {
    const metadata = {
      contentType: newPubImage.type
    };

    const uploadTask = await firebaseStorage
      .ref(`pub/${pubId}/image/256x256`)
      .put(newPubImage, metadata);

    const url = await uploadTask.ref.getDownloadURL();

    const newPub: PubFormApi = { ...pubForm, image: url };
    await firestore
      .collection("pub")
      .doc(pubId)
      .update(newPub);
    return newPub;
  }

  await firestore
    .collection("pub")
    .doc(pubId)
    .update(pubForm);

  return pubForm;
}
