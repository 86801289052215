import {
  PaymentFormType,
  PaymentForm
} from "./models";
import * as img from "../../img/payment-form";
import { firestore } from "../../firebase";

export const onceGetPaymentForms = async () => {
  const response = await firestore.collection("paymentForms").get();
  const paymentForms = response.docs.map(doc => {
    const data = doc.data() as PaymentForm;
    return {
      uid: doc.id,
      ...data
    } as PaymentForm;
  });
  return paymentForms;
};

export const getPaymentFormName = (payment: PaymentForm) => {
  return `${payment.name}${getPaymentType(payment.paymentFormsType)}`;
};

const getPaymentType = (type: PaymentFormType) => {
  switch (type) {
    case PaymentFormType.Credit:
      return " - Crédito";
    case PaymentFormType.Debit:
      return " - Débito";
    case PaymentFormType.MealTicket:
    case PaymentFormType.Money:
    default:
      return "";
  }
};

export const getPaymentImage = (paymentName: string | null) => {
  if (!paymentName) {
    return null;
  }

  const type = paymentName.split(" - ").find(a => !!a);

  if (!type) {
    return null;
  }

  const name = type.trim();

  switch (name) {
    case "Alelo":
      return img.alelo;
    case "Amex":
      return img.amex;
    case "Cheque":
      return img.cheque;
    case "Crediário":
      return img.crediario;
    case "Dinheiro":
      return img.dinheiro;
    case "Dinners":
      return img.dinners;
    case "Elo":
      return img.elo;
    case "Hipercard":
      return img.hiper;
    case "Mastercard":
      return img.master;
    case "Visa":
      return img.visa;
    case "VR":
      return img.vr;
    default:
      return null;
  }
};
