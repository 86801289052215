import {
  Service,
  FirestoreService,
  FirestoreServiceMap,
  FirestoreOrder,
  FirestorePayment,
  FirestoreUser,
  Order,
  OrderMap,
  PaymentMap,
  Payment,
  FirestoreUserMap
} from "./models";
import { usePubId } from "../pub/state";
import { useEffect, useContext, useReducer, useMemo } from "react";
import {
  getActiveServicesRef,
  convertService,
  getOrdersRef,
  getUsersRef,
  getPaymentsRef,
  convertOrder,
  convertPayment
} from "./api";
import createContainer from "constate";
import * as dotProp from "dot-prop-immutable";

export interface ServiceState {
  services: FirestoreServiceMap;
  orders: OrderMap;
  payments: PaymentMap;
  users: FirestoreUserMap;
}

export type ServiceActions =
  | {
      readonly type: "UPDATE_SERVICES";
      readonly payload: FirestoreServiceMap;
    }
  | {
      readonly type: "ADD_ORDERS";
      readonly payload: OrderMap;
    }
  | {
      readonly type: "ADD_ORDER";
      readonly payload: { id: string; data: Order };
    }
  | {
      readonly type: "UPDATE_ORDER";
      readonly payload: { id: string; data: Order };
    }
  | {
      readonly type: "REMOVE_ORDER";
      readonly payload: { id: string };
    }
  | {
      readonly type: "ADD_USER";
      readonly payload: { id: string; data: FirestoreUser };
    }
  | {
      readonly type: "UPDATE_USER";
      readonly payload: { id: string; data: FirestoreUser };
    }
  | {
      readonly type: "REMOVE_USER";
      readonly payload: { id: string };
    }
  | {
      readonly type: "ADD_PAYMENTS";
      readonly payload: PaymentMap;
    }
  | {
      readonly type: "ADD_PAYMENT";
      readonly payload: { id: string; data: Payment };
    }
  | {
      readonly type: "UPDATE_PAYMENT";
      readonly payload: { id: string; data: Payment };
    }
  | {
      readonly type: "REMOVE_PAYMENT";
      readonly payload: { id: string };
    };

const initialState: ServiceState = {
  services: {},
  orders: {},
  payments: {},
  users: {}
};

function reducer(state: ServiceState, action: ServiceActions): ServiceState {
  switch (action.type) {
    case "ADD_ORDERS":
      return { ...state, orders: action.payload };
    case "UPDATE_SERVICES":
      return { ...state, services: action.payload };
    case "ADD_ORDER":
    case "UPDATE_ORDER":
      return {
        ...state,
        orders: dotProp.merge(
          state.orders,
          action.payload.id,
          action.payload.data
        )
      };
    case "REMOVE_ORDER":
      return {
        ...state,
        orders: dotProp.delete(state.orders, action.payload.id)
      };

    case "ADD_USER":
    case "UPDATE_USER":
      return {
        ...state,
        users: dotProp.merge(
          state.users,
          action.payload.id,
          action.payload.data
        )
      };
    case "REMOVE_USER":
      return {
        ...state,
        users: dotProp.delete(state.users, action.payload.id)
      };

    case "ADD_PAYMENTS":
      return { ...state, payments: action.payload };
    case "ADD_PAYMENT":
    case "UPDATE_PAYMENT":
      return {
        ...state,
        payments: dotProp.merge(
          state.payments,
          action.payload.id,
          action.payload.data
        )
      };
    case "REMOVE_PAYMENT":
      return {
        ...state,
        payments: dotProp.delete(state.payments, action.payload.id)
      };

    default:
      return state;
  }
}

function useService() {
  const pubId = usePubId();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (pubId) {
      const activeServicesRef = getActiveServicesRef(pubId);
      let services: FirestoreServiceMap = {};

      const unsubscribeServices = activeServicesRef.onSnapshot(snapshot => {
        services = {};
        snapshot.docs.forEach(doc => {
          const serviceId = doc.id;
          const firestoreService = doc.exists
            ? (doc.data() as FirestoreService)
            : null;

          if (serviceId && firestoreService) {
            services = { ...services, [serviceId]: firestoreService };
          }
        });

        dispatch({ type: "UPDATE_SERVICES", payload: services });
      });

      const ordersRef = getOrdersRef(pubId);
      const initialOrder: OrderMap = {};
      let isFirst = true;

      const unsubscribeOrders = ordersRef.onSnapshot(snapshot => {
        const docChanges = snapshot.docChanges();

        if (isFirst) {
          isFirst = false;

          const orders = docChanges.reduce((memo, change) => {
            const id = change.doc.id;
            const data = change.doc.data() as FirestoreOrder;
            const order = convertOrder(id, data);
            memo[id] = order;
            return memo;
          }, initialOrder);

          dispatch({ type: "ADD_ORDERS", payload: orders });

          return false;
        }

        docChanges.forEach(change => {
          const id = change.doc.id;
          const data = change.doc.exists
            ? (change.doc.data() as FirestoreOrder)
            : null;

          if (change.type === "added" && id && data) {
            const order = convertOrder(id, data);
            dispatch({ type: "ADD_ORDER", payload: { id, data: order } });
          }

          if (change.type === "modified" && id && data) {
            const order = convertOrder(id, data);
            dispatch({ type: "UPDATE_ORDER", payload: { id, data: order } });
          }

          if (change.type === "removed" && id) {
            dispatch({ type: "REMOVE_ORDER", payload: { id } });
          }
        });
      });

      const usersRef = getUsersRef(pubId);
      const unsubscribeUsers = usersRef.onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          const id = change.doc.id;
          const data = change.doc.exists
            ? (change.doc.data() as FirestoreUser)
            : null;

          if (change.type === "added" && id && data) {
            dispatch({ type: "ADD_USER", payload: { id, data } });
          }

          if (change.type === "modified" && id && data) {
            dispatch({ type: "UPDATE_USER", payload: { id, data } });
          }

          if (change.type === "removed" && id) {
            dispatch({ type: "REMOVE_USER", payload: { id } });
          }
        });
      });

      let isFirstPayment = true;
      const paymentsRef = getPaymentsRef(pubId);
      const initialPayment: PaymentMap = {};
      const unsubscribePayments = paymentsRef.onSnapshot(snapshot => {
        const docChanges = snapshot.docChanges();

        if (isFirstPayment) {
          isFirstPayment = false;
          const payments = docChanges.reduce((memo, change) => {
            const id = change.doc.id;
            const data = change.doc.data() as FirestorePayment;
            const payment = convertPayment(id, data);
            memo[id] = payment;
            return memo;
          }, initialPayment);

          dispatch({ type: "ADD_PAYMENTS", payload: payments });
          return false;
        }

        docChanges.forEach(change => {
          const id = change.doc.id;
          const data = change.doc.exists
            ? (change.doc.data() as FirestorePayment)
            : null;

          if (change.type === "added" && id && data) {
            const payment = convertPayment(id, data);
            dispatch({ type: "ADD_PAYMENT", payload: { id, data: payment } });
          }

          if (change.type === "modified" && id && data) {
            const payment = convertPayment(id, data);
            dispatch({
              type: "UPDATE_PAYMENT",
              payload: { id, data: payment }
            });
          }

          if (change.type === "removed" && id) {
            dispatch({ type: "REMOVE_PAYMENT", payload: { id } });
          }
        });
      });

      return () => {
        unsubscribeServices();
        unsubscribeOrders();
        unsubscribeUsers();
        unsubscribePayments();
      };
    }
  }, [pubId]);

  const services: Service[] = useMemo(() => {
    return Object.entries(state.services).map(([serviceId, service]) => {
      return convertService(
        serviceId,
        service,
        state.orders,
        state.payments,
        state.users
      );
    });
  }, [state]);

  return { ...state, services };
}

export const ServiceContainer = createContainer(useService);

export function useServiceList() {
  const state = useContext(ServiceContainer.Context);
  return state.services;
}
