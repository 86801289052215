import React from "react";
import { Theme, Typography, IconButton } from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/styles";

interface Props {
  value: number;
  onIncrease: () => void;
  onDecrease: () => void;
  min?: number;
  max?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  btnGroup: {
    display: "flex",
    marginLeft: theme.spacing(1),
    flexDirection: "column"
  },
  btn: {
    padding: 0
  }
}));

function NumberCount(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body1">{props.value}</Typography>
      <div className={classes.btnGroup}>
        <IconButton
          className={classes.btn}
          onClick={props.onIncrease}
          disabled={props.max ? props.value >= props.max : false}
        >
          <ArrowDropUp />
        </IconButton>
        <IconButton
          className={classes.btn}
          onClick={props.onDecrease}
          disabled={props.min ? props.value <= props.min : false}
        >
          <ArrowDropDown />
        </IconButton>
      </div>
    </div>
  );
}

export default NumberCount;
