import React from "react";
import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface Props {
  title: string;
  buttons?: React.ReactNode[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "rgb(230, 231, 236)",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 52 + theme.spacing(2)
  },
  title: {
    marginLeft: theme.spacing(1)
  },
  btnGroup: {},
  btn: {
    margin: theme.spacing(1)
  }
}));

function ContentHeader(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        {props.title}
      </Typography>
      <div className={classes.btnGroup}>
        {props.buttons &&
          props.buttons.map((Button, i) => (
            <div key={i} className={classes.btn}>
              {Button}
            </div>
          ))}
      </div>
    </div>
  );
}

export default ContentHeader;
