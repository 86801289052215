import { useState, useEffect, useContext } from "react";
import { firebaseAuth } from "../../firebase";
import { getUserData } from "./api";
import { User } from "./models";
import { userApi } from "../../api";
import { getPub } from "../pub/api";
import { Pub } from "../pub/models";
import { confirmableSnackbar } from "../../components";
import createContainer from "constate";

interface AuthProps {
  isLoggedIn: boolean;
  loading: boolean;
  userId: string | null;
  user: User | null;
  pub: Pub | null;
  pubId: string | null;
}

const tuple = <T extends any[]>(...args: T): T => args;

function useAuth() {
  const [state, setState] = useState({
    isLoggedIn: false,
    loading: true,
    user: null,
    userId: null,
    pub: null,
    pubId: null
  } as AuthProps);

  function updatePub(pub: Pub) {
    setState({ ...state, pub });
  }

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async user => {
      if (user) {
        try {
          setState(prevState => {
            return { ...prevState, loading: true };
          });

          console.log(user.uid, user.email);
          const token = await user.getIdToken();
          userApi.defaults.headers.common.fbToken = token;
          console.log(token);
          const userData = await getUserData(user.uid);
          console.log(userData);

          const { pubs = {} } = userData;
          const currentPub = localStorage.getItem("pubId");
          const pubId = currentPub || Object.keys(pubs).find(pub => !!pub);

          if (!pubId) {
            throw new Error(`Pub not found for user ${user.uid}`);
          }

          const pubData = await getPub(pubId);
          console.log(pubData);

          setState({
            isLoggedIn: true,
            loading: false,
            user: userData,
            pub: pubData,
            userId: user.uid,
            pubId
          });
        } catch (e) {
          setState({
            isLoggedIn: false,
            loading: false,
            user: null,
            userId: null,
            pub: null,
            pubId: null
          });
          confirmableSnackbar("User not found", "error");
          firebaseAuth.signOut();
        }
      } else {
        console.log("logged out");
        localStorage.removeItem("pubId");
        setState({
          isLoggedIn: false,
          loading: false,
          user: null,
          userId: null,
          pub: null,
          pubId: null
        });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return tuple(state, updatePub);
}

export function useCurrentUser() {
  const [state] = useContext(AuthContainer.Context);
  return state.user;
}

export function useCurrentUserId() {
  const [state] = useContext(AuthContainer.Context);
  return state.userId;
}

const AuthContainer = createContainer(useAuth);

export default AuthContainer;
