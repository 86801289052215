import React from "react";
import { CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles({
  content: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden"
  }
});

function CustomCardContent(props: Props) {
  const classes = useStyles();

  return (
    <CardContent className={classes.content}>{props.children}</CardContent>
  );
}

export default CustomCardContent;
