import React, { Suspense } from "react";
import AuthContainer from "../../features/auth/state";
import { HashRouter as Router } from "react-router-dom";
import Container from "../container";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import "../../lang";
import { Loading } from "../../components";
import ExampleBoundary from "../error-boundary";
import { ServiceContainer } from "../../features/service/state";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "rgb(218, 64, 60)",
      main: "rgb(218, 64, 60)",
      dark: "rgb(163, 34, 31)",
      contrastText: "#fff"
    },
    text: {
      primary: "rgb(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)"
    }
  },
  typography: {
    fontSize: 12
  }
});

export { theme };

const basePath = process.env.REACT_APP_BASE_PATH || "";

function App() {
  return (
    <ExampleBoundary>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading />}>
          <Router basename={basePath}>
            <AuthContainer.Provider>
              <ServiceContainer.Provider>
                <Container />
              </ServiceContainer.Provider>
            </AuthContainer.Provider>
          </Router>
        </Suspense>
      </ThemeProvider>
    </ExampleBoundary>
  );
}

export default App;
