import { useContext } from "react";
import { dbPubUpdate } from "./api";
import { PubForm, Pub, PubFormApi } from "./models";
import { FormikActions } from "formik";
import { confirmableSnackbar } from "../../components";
import AuthContainer from "../auth/state";
import { ensureNotNull } from "../../utils/helper";

const tuple = <T extends any[]>(...args: T): T => args;

export function useBlockEditing() {
  const pub = useCurrentPub();
  return pub.statusBlockMenuEditing && pub.statusBlockMenuEditing === "A";
}

export function useCurrentPub() {
  const [auth] = useContext(AuthContainer.Context);
  return ensureNotNull(auth.pub, "pub");
}

export function useCurrentPubId() {
  const [auth] = useContext(AuthContainer.Context);
  return ensureNotNull(auth.pubId, "pubId");
}

export function usePubId() {
  const [auth] = useContext(AuthContainer.Context);
  return auth.pubId;
}

function postLoadFormat(values: Pub): PubForm {
  return {
    ...values,
    description: values.description || "",
    additionalInformation: values.additionalInformation || "",
    phone: values.phone || "",
    instagram: values.instagram || "",
    facebook: values.instagram || "",
    website: values.website || "",
    tableService: values.tableService === "S",
    balconyService: values.balconyService === "S",
    tableReservation: values.tableReservation === "S",
    statusIntegrationPOS:
      !!values.statusIntegrationPOS && values.statusIntegrationPOS === "A",
    statusBlockMenuEditing:
      !!values.statusBlockMenuEditing && values.statusBlockMenuEditing === "A"
  };
}

function preSaveFormat(values: PubForm): PubFormApi {
  return {
    ...values,
    tableService: values.tableService ? "S" : "N",
    balconyService: values.balconyService ? "S" : "N",
    tableReservation: values.tableReservation ? "S" : "N",
    statusIntegrationPOS: values.statusIntegrationPOS ? "A" : "I",
    statusBlockMenuEditing: values.statusBlockMenuEditing ? "A" : "I"
  };
}

export function usePubForm() {
  const pub = useCurrentPub();
  const pubId = useCurrentPubId();
  const [, updatePub] = useContext(AuthContainer.Context);

  const initialValues = pub ? postLoadFormat(pub) : null;

  async function onSubmit(
    values: PubForm | null,
    actions: FormikActions<PubForm | null>
  ) {
    if (!pub || !values) {
      return false;
    }

    const pubSaveData = preSaveFormat(values);

    try {
      const newPub = await dbPubUpdate(pubId, pubSaveData);
      updatePub({ ...pub, ...newPub });
      confirmableSnackbar("Pub updated successfully", "success");
    } catch (e) {
      confirmableSnackbar("Error trying to save pub", "error");
    } finally {
      actions.setSubmitting(false);
    }
  }

  return tuple(initialValues, onSubmit);
}
