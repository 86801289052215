import React from "react";
import { makeStyles } from "@material-ui/styles";

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    background: "rgb(240, 241, 247)",
    minHeight: "100%"
  }
});

function Root(props: Props) {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
}

export default Root;
