import React from "react";
import { Theme, Typography } from "@material-ui/core";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next/hooks";

interface Props extends RouteComponentProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "rgb(38, 36, 45)",
    paddingTop: theme.spacing(3),
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  ul: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    textAlign: "left",
  },
  link: {
    textDecoration: "none",
  },
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    color: "rgb(140, 142, 144)",
    borderLeftWidth: theme.spacing(1),
    borderLeftColor: "transparent",
    borderLeftStyle: "solid",
    cursor: "pointer",
    "&:hover": {
      color: "#fff",
      borderLeftColor: theme.palette.primary.dark,
      background: theme.palette.primary.main,
    },
  },
  itemActive: {
    color: "#fff",
    borderLeftColor: theme.palette.primary.dark,
    background: theme.palette.primary.main,
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 500,
  },
  version: {
    marginTop: "auto",
    padding: theme.spacing(2),
  },
  versionTxt: { color: "rgb(140, 142, 144)" },
}));

function Navigation(props: Props) {
  const classes = useStyles();
  const [t] = useTranslation(["navigation"]);

  return (
    <div className={classes.root}>
      <ul className={classes.ul}>
        <li>
          <Link to="/" className={classes.link}>
            <Typography variant="body1" className={classes.item}>
              {t("navigation:::Home")}
            </Typography>
          </Link>
        </li>
        <li>
          <Typography variant="body1" className={classes.title}>
            {t("navigation:::Restaurant")}
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Link to="/menu" className={classes.link}>
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.item,
                    props.match.url.indexOf("/menu") === 0 && classes.itemActive
                  )}
                >
                  {t("navigation:::Menu")}
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/sale" className={classes.link}>
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.item,
                    props.match.url.indexOf("/sale") === 0 &&
                      props.match.url.indexOf("/sale-day") !== 0 &&
                      classes.itemActive
                  )}
                >
                  {t("navigation:::Sales")}
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/department" className={classes.link}>
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.item,
                    props.match.url.indexOf("/department") === 0 &&
                      classes.itemActive
                  )}
                >
                  {t("navigation:::Departments and tables")}
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/pub" className={classes.link}>
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.item,
                    props.match.url === "/pub" && classes.itemActive
                  )}
                >
                  {t("navigation:::Profile")}
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/service" className={classes.link}>
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.item,
                    props.match.url === "/service" && classes.itemActive
                  )}
                >
                  Atendimentos
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/bartender-form" className={classes.link}>
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.item,
                    props.match.url.indexOf("/bartender-form") === 0 &&
                      classes.itemActive
                  )}
                >
                  Chamar garçom
                </Typography>
              </Link>
            </li>
            <li>
              <Link to="/product-list" className={classes.link}>
                <Typography
                  variant="body1"
                  className={classNames(
                    classes.item,
                    props.match.url.indexOf("/product-list") === 0 &&
                      classes.itemActive
                  )}
                >
                  Lista de produtos
                </Typography>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
      <div className={classes.version}>
        <Typography variant="body1" className={classes.versionTxt}>
          {t("navigation:::Version")} {process.env.REACT_APP_VERSION}
        </Typography>
      </div>
    </div>
  );
}

export default withRouter(Navigation);
